/**
 Structure:
 *  - BLOCK general.css
 *      - General css
 *      - navbar, sidebar
 *      - Logo styles
 *      - Page content layout
 *      - Boot customized
 *      - Helper styles
 *  - BLOCK common-components.css
 *      - Custom alerts for notification
 *      - Development Ribbon
 *      - Table filter
 *      - entity tables helpers
 *      - entity detail page css
 *      - Toggle component
 *  - BLOCK <component>.css
 *      - Metrics and Health styles
 *      - Password strength bar style
 *      - Upload 210
 *      - login form
 *      - Incomes and Incomes Detail page
 *      - Attendance page styles
 * /


/* #########################
 *
 *   BLOCK general.css
 *
 *   Generic HTML document styles,
 *   page layout and customized boot styles
 *
 ########################## */

html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #666;
    margin-bottom: 70px;
}

#foot {
    position: absolute;
    z-index: 20;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    background-color: #f5f5f5;
    padding-top: 15px;
    text-align: center;
    font-size: 14px;
    border-top: 1px solid #dcdfe4;
}

.footer-list, .footer-social-buttons {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
}
.footer-list-item, .footer-social-buttons li {
    display: inline-block;
    color: #999;
}
.footer-list-item:not(:last-child):after {
    content: "\b7";
}

#head {
    position: relative;
    z-index: 20;
}


input::-webkit-input-placeholder {
    color: #ccc;
}

/* disabling arrows in number field. Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media (min-width: 768px) {

    body {
        background: #edf1f5;
    }

    #content {
        padding: 0 25px;
        margin: 0 0 0 220px;
    }

    .sidebar {
        z-index: 10;
        position: fixed;
        top: 0;
        width: 220px;
        padding-top: 60px;
        height: 100%;
        background: white;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    }

    .navbar-header {
        width: 220px;
    }

    .navbar-header-fixed {
        position: fixed;
    }

    .sidebar .navbar-sidebar {
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .sidebar .navbar-sidebar .navbar-collapse {
        overflow-y: auto !important;
        height: 100% !important;
    }

    /** full screen view */
    body.view-fullscreen #collapse-sidebar-icon {
        transform: rotateY(180deg);
    }

    body.view-fullscreen .collapsible,
    body.view-fullscreen .sidebar .nav-sep {
        display: none;
    }

    body.view-fullscreen .navbar-brand {
        padding: 15px 5px;
    }

    body.view-fullscreen .navbar-brand .logo-img {
        height: 22px
    }

    body.view-fullscreen .sidebar,
    body.view-fullscreen .navbar-header {
        width: 50px
    }

    body.view-fullscreen #content {
        margin-left: 50px;
    }
}

@media (min-width: 1200px) {
    #content {
        margin: 0 0 0 280px;
    }

    .sidebar,
    .navbar-header {
        width: 280px;
    }

    .navbar-header .navbar-brand.logo {
        font-size: 1.5em;
    }
}

@media screen and (max-width: 768px) {
    input[type="radio"], input[type="checkbox"] {
        -moz-transform: scale(1.5);
    }
}

/** admin page fix */
#adm #content {
    margin:0;
    padding: 20px;
    background-color: white;
}

/* ==========================================================================
Navbars styles
========================================================================== */
.navbar-sidebar,
.navbar-default {
    border: none;
    border-radius: 0;
}
#head .navbar-default {
    background-color: #4F5467;
    color: #fff;
}

#head .navbar {
    margin: 0;
}

#head .navbar-header {
    background: white;
}

#head .navbar .navbar-form { margin-left: 0; margin-right: 0; }

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #fff;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #747786;
}

.navbar-header .navbar-toggle .icon {
    color: #888;
    padding: 0;
    font-size: 18px;
    width: 22px;
}

/*** sidebar ***/
.sidebar {
    border-bottom: 12px solid #ECEFF1;
}

.sidebar .navbar-default {
    background-color: white;
}

.navbar-sidebar .navbar-collapse {
    padding: 0;
}

.nav-sidebar li .glyphicon {
    margin-right: 5px;
}

.nav-sidebar li a {
    color: #54667a;
    padding: 15px 30px 15px 15px;
}
.nav-sidebar li.dropdown > .nav.collapsible a {
    padding-left: 30px;
}


.nav-sidebar li {
    border-left: 3px solid transparent;
}

.nav-sidebar li.active {
    border-left: 3px solid #fb9678;
    background-color: #f5f5f5;
}

.nav-sidebar li.active > a > .glyphicon,
.nav-sidebar li.active-root > a > .glyphicon {
    color: #fb9678;
}

.nav-shade li a {
    color: #9e9e9e;
}

.nav-sep {
    color: #a6afbb;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .035em;
    padding-top: 15px;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand {
    color: #4f5467;
    overflow: hidden;
}

.logo .logo-img {
    height: 35px;
    display: inline-block;
}

.version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}


/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


/* ==========================================================================
Page content layout
========================================================================== */
@media screen and (min-width: 768px) {
    .pg-title {
        background: #fff;
        overflow: hidden;
        padding: 15px 15px 10px;
        margin-bottom: 25px;
        margin-left: -40px;
        margin-right: -40px;
    }

    .pg-title h3,
    .pg-title h4 {
        color: rgba(0, 0, 0, .5);
        font-weight: 600;
        margin: 0;
    }

    .box {
        padding: 25px;
        margin: 0 2px 20px 2px;
    }

    .row-top {
        padding-top: 25px;
    }
}

.pg-action {
    text-align: right;
}

.box {
    background: #fff;
    margin-bottom: 20px;
}

.pg-title {
    padding: 0 15px;
    margin-bottom: 25px;
}

.pg-title .link-back {
    display: inline-block;
    margin-right: 20px;
    float: left;
    font-size: 1.5em;
}
.pg-title .link-back a { color: #9E9E9E; }
.pg-title .link-back a:hover { color: #fb9678;}
.pg-title .link-back .glyphicon {
    transform: rotateY(180deg);
}

.row-tools {
    margin-bottom: 2px;
}

.minor-link-btn {
    padding: 6px 12px;
    display: inline-block;
}

.minor-link-btn a,
.minor-link {
    font-weight: normal;
    border-bottom: 1px dotted;
    text-decoration: none;
    color: #666;
}
.minor-link-btn a:hover {color: #9e9e9e;}


/* ==========================================================================
Boot customized
========================================================================== */
.btn {
    border: none;
    border-radius: 2px;
    position: relative;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    will-change: box-shadow, transform;
}

.btn-border {
    border: 1px solid #ccc;
}

.btn-primary {
    background-color: #009688;
}
.btn-primary.btn-inactive {
    background-color: #80CBC4;
}

.btn-primary.disabled:hover, .btn-primary[disabled]:hover,
.btn-primary:active, .btn-primary:hover, .btn-primary:focus, .btn-primary:active:hover {
    background-color: #00aa9a;
}

.btn.glyphicon{
    background: none;
    font-weight: inherit;
    padding: 0 5px;
    margin: 0;
}

.table .btn-group .btn {
    color: #777;
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
}
.table .btn-group .btn[disabled],
.table .btn-group .btn.btn-inactive {
    color: #ccc;
}

.table .btn-group .btn:hover, .table .btn-group .btn.active {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc;
}

.table .btn-group .btn-danger:hover, .table .btn-group .btn-danger.active {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
}

.table .btn-group .btn-warning:hover, .table .btn-group .btn-warning.active {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #cb9742;
}

.table .btn-group .btn-primary:hover, .table .btn-group .btn-primary.active {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
}

.table {
    font-family: 'Roboto', sans-serif;
}

.table > thead > tr > th {
    color: #666;
    font-size: 0.9em;
    border-bottom: 1px solid #ddd;
}

.sp-table.table > thead > tr.filter-row > th {
    background-color: #FAFAFA;
    border-top: 1px solid #ddd;
}

.table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.table > thead > tr > th > .glyphicon-sort, .sp-table > thead > tr > th > .glyphicon-sort-by-attributes, .sp-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.table > thead > tr > th > .glyphicon-sort:hover, .sp-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .sp-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
}

.table th[jh-sort-by] {
    cursor: pointer;
}

.table .fixcol {
    position: sticky;
    left: 0;
    z-index: 2;
}
.table th.fixcol {
    background-color: white;
}
.table td.fixcol {
    background-color: white;
}

.toggle-filter {
    padding-bottom: 5px;
}

.help-block.label-warning {
    color: #EF6C00;
    background-color: transparent;
}

.label {font-weight: normal;}

.label.label-fail {
    color: white;
    background-color: #d9534f;
}

.help-block {
    margin-bottom: 5px;
}

.form-horizontal hr {
    margin-top: 5px;
}

.form-group .form-group {
    margin-bottom: 5px;
}

.alert {
     margin-bottom: 10px;
}

.alert-warning .alert-link {
    color: #4CAF50;
}

.pagination > .active > a, .pagination > .active > span,
.pagination > .active > a:hover, .pagination > .active > span:hover,
.pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #31b0d5;
    border-color: #31b0d5;
}
.pagination > li > a {
    border: none;
}

.main .nav-tabs {
    margin-bottom: 30px;
}
.main .nav-tabs > li.active > a { color: #fb9678; }


/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (   min--moz-device-pixel-ratio: 2), only screen and (     -o-min-device-pixel-ratio: 2/1), only screen and (        min-device-pixel-ratio: 2), only screen and (                min-resolution: 192dpi), only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Helper styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {padding: 10px;}

.break {
    white-space: normal;
    word-break: break-all;
}

.mt-2   {margin-top: 2px;}
.mt-5   {margin-top: 5px;}
.mt-10  {margin-top: 10px;}
.mt-15  {margin-top: 15px;}
.mt-30  {margin-top: 30px;}
.mt-40  {margin-top: 40px;}
.mt-60  {margin-top: 60px;}
.mt-80  {margin-top: 80px;}
.mt-100 {margin-top: 100px;}
.mt-150 {margin-top: 150px;}

.mr-5   {margin-right: 5px}
.mr-10  {margin-right: 10px}

.font-05x {font-size: 0.75em;}
.font-5x {font-size: 5em;}
.font-3x {font-size: 3em;}
.font-2x {font-size: 2em;}
.font-1n5x {font-size: 1.5em;}

.w10 { width: 10%; }
.w20 { width: 20%; }
.w30 { width: 30%; }
.w40 { width: 40%; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

.highlighted {background-color: #FFF9C4;}

.drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    height: 65px;
    text-align: center;
    margin: 10px 0;
    padding-top: 16px;
}

/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left  {padding-left: 0 !important;}
.no-padding-right {padding-right: 0 !important;}
.no-padding-top   {padding-top: 0 !important;}
.no-padding-bottom {padding-bottom: 0 !important;}
.no-padding {padding: 0 !important;}

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
    width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
    cursor: pointer;
}
.hand {
    cursor: pointer;
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
    outline: none;
}


/* ==========================================================================
 Form style
========================================================================== */

input.ng-dirty.ng-touched {
    border-color: lightgreen;
}
input.ng-dirty.ng-invalid {
    border-color: red;
}

 /* <<<<<<<<<<<<<< END BLOCK general.css */




/* #########################
 *
 *  BLOCK common-components.css
 *  Components, widgets and block common for whole application
 *
 * ########################## */

#loading-bar .bar {
    background: #fb9678;
}

#collapse-sidebar-icon {
    margin-top: 10px;
    transition: all 0.3s linear;
    cursor: pointer;
    font-size: 2em;
    opacity: 0.25;
}
#collapse-sidebar-icon:hover { opacity: 1; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
    width: 60%;
    position: fixed;
    left: 22%;
    height: 15px;
    z-index: 100;
}

.alerts .alert {
    text-overflow: ellipsis;
}

.alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left   {left: 5px;}
.alerts .toast.right  {right: 5px;}
.alerts .toast.top    {top: 55px;}
.alerts .toast.bottom {bottom: 55px;}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}


/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Table filter
========================================================================== */
.table-fitler {
    /*display: table;*/
    border: 1px solid #ccc;
    width: 100%;
    line-height: 22px;
}

.table-fitler .input-group-btn {
    width: 18px;
    background-color: white;
}
.input-group-btn .btn {
    border: 1px solid #ccc;
}

.btn-x {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvVFIvMjAwMS9SRUMtU1ZHLTIwMDEwOTA0L0RURC9zdmcxMC5kdGQnPjxzdmcgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGc+PHBhdGggZD0iTTEyLDJDNi41LDIsMiw2LjUsMiwxMmMwLDUuNSw0LjUsMTAsMTAsMTBzMTAtNC41LDEwLTEwQzIyLDYuNSwxNy41LDIsMTIsMnogTTE2LjksMTUuNWwtMS40LDEuNEwxMiwxMy40bC0zLjUsMy41ICAgbC0xLjQtMS40bDMuNS0zLjVMNy4xLDguNWwxLjQtMS40bDMuNSwzLjVsMy41LTMuNWwxLjQsMS40TDEzLjQsMTJMMTYuOSwxNS41eiIvPjwvZz48L3N2Zz4=);
    cursor: pointer;
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 2px;
}

.table-fitler input {
    height: 26px;
    width: 100%;
    font-weight: normal;
    border: none;
    padding: 2px 2px 2px 8px;
    outline: none;
    box-shadow: none;
}

/** ---------------------
 * Pricing Table footer
 */
.table-totals {
    padding: 5px 10px;
    background-color: #eee;
    margin-top: -10px;
    margin-bottom: 25px;
    text-align: right;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

.hipster-access-err {
    display: inline-block;
    width: 100%;
    height: 200px;
    background: url(../images/error-morty.png) no-repeat center center;
    background-size: contain;
    margin-top:25px;
    margin-bottom: -33px;
}

.hipster-err {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/error-robot.png") no-repeat center top;
    background-size: contain;
}

/* ==========================================================================
entity tables helpers
========================================================================== */
.sp-table > tbody > .row-current.row-success {
    background-color: #b9ecb9;
}

.sp-table > tbody > .row-current.row-warn {
    background-color: #ffe0b2;
}

.sp-table > tbody > .row-current.row-error {
    background-color: #ffa099;
}

.sp-table > tbody > .row-current.row-fail {
    background-color: #ff706b;
}

.sp-table.table-marked > tbody > tr {
    border-left: 4px solid transparent;
}

.sp-table.table-marked > tbody > tr.row-warn {
    border-color: #fec107;
}

.sp-table.table-marked > tbody > tr.row-error {
    border-color: #fb9678;
}

.sp-table-filter {
    color: #333;
    padding: 10px 15px;
    margin-bottom: 10px;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details {
    background-color: #fffcf0;
}

.dl-horizontal.jh-report-details {
    background-color: #dff0d8;
}

.jh-report-details .total {
    color: red;
}

.dl-horizontal {
    padding-top: 10px;
    padding-left: 10px;
}

.dl-horizontal > dd {
    margin-bottom: 10px;
}

.dl-horizontal pre.pre {
    padding: 0;
    margin: 0 0 10px;
    font-size: inherit;
    background: none;
    border: none;
    white-space: normal;
    word-break: break-word;
}

@media screen and (min-width: 768px) {
    .dl-horizontal {
        padding-left: 0;
    }

    .dl-horizontal > dt {
        margin-bottom: 5px;
    }

    .dl-horizontal > dd {
        padding-left: 180px;
        margin-left: 0;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #efe5b5;
    }

    .dl-horizontal.jh-report-details > dd {
        border-bottom: 1px solid #8bc34a;
    }

    .dl-horizontal.dl-import > dt {
        width: 200px;
    }

    .dl-horizontal.dl-import > dd {
        padding-left: 220px;
    }

}

/* ==========================================================================
Toggle component
========================================================================== */
.form-horizontal .toggle-switch-block {
    padding-top: 6px;
}

/* The switch - the box around the slider */
.toggle-switch-block .switch {
    vertical-align: middle;
    margin-top: 2px;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

/* Hide default HTML checkbox */
.toggle-switch-block .switch input {
    display: none;
}

/* The slider */
.toggle-switch-block .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.toggle-switch-block .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.toggle-switch-block input:checked + .slider {
    background-color: #2196F3;
}

.toggle-switch-block input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

.toggle-switch-block input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.toggle-switch-block .slider.round {
    border-radius: 17px;
}
.toggle-switch-block .slider.round:before {
    border-radius: 50%;
}

 /* <<<<<<<<<<<<<< END BLOCK common-components.css */






 /* #########################
 *
 *  BLOCK <component>.css
 *  Page-specific components. Ideally each should be placed in its own css file.
 *
 * ########################## */


/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
Password strength bar style
========================================================================== */
ul#strengthBar {
    display: inline;
    list-style: none;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    vertical-align: 2px;
}

#strengthBar .point:last {
    margin: 0 !important;
}

#strengthBar .point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
}


/* ==========================================================================
Upload 210's component
========================================================================== */
.upload-box .dragover {border-color: #CCC;}
.upload-box .upload-status-item {
    height: 8px;
    margin-bottom: 0;
    border-radius: 0;
}


/* ==========================================================================
login form
========================================================================== */
.login input.form-control {
    border: 1px solid #e4e7ea;
    border-radius: 0;
    box-shadow: none;
    color: #565656;
    max-width: 100%;
    padding: 7px 12px;
}

.login .form-control:focus {
    border-color: #66afe9;
    outline: 0;
}

.login .login-btn {
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
}

.login label {
    font-weight: normal;
}


/* ==========================================================================
 Incomes and Incomes Detail page
========================================================================== */
.tabset {
    margin-bottom: 10px
}

.tabset .tabset-footer {
    padding: 15px;
    text-align: right;
}

.incomes .row {
    margin: 10px;
}

.incomes .row .col {
    padding: 2px;
}

.incomes .title-row {
    text-align: center;
}

.incomes .result-row,
.incomes .incomes-title {
    font-size: larger;
    text-align: center;
    line-height: 2em;
}

.incomes .result-row,
.incomes .incomes-title.text-left {
    text-align: left;
}

.incomes .result-row .col {
    text-align: left;
    padding-left: 10px;
}

.incomes .result-row .col:first-child {
    text-align: right;
}

/** School-income page */
.incomes-table,
.incomes-table th {
    text-align: center;
}

/*
*   Fixed table header for school incomes table
*/
.fixed-header-table-wrapper {
    max-height: 70vh;
    overflow-x: auto;
    overflow-y: scroll;
}

.fixed-header-table-wrapper table thead th {
    padding: 0;
}

.fixed-header-table-wrapper table th div {
    height: 34px;
    min-width: 61px;
    padding-top: 8px;
}

.fixed-header-table-wrapper table th[rowspan="2"] div {
    height: 68px;
    min-width: 61px;
    padding-top: 25px;
}

.fixed-header-table-wrapper table thead div {
    border: 1px solid lightgray;
    background-color: white;
    margin: -1px;
}

.fixed-header-table-wrapper table .flex-btn-group-container{
    display: inline-block;
    position: static;
}

.fixed-header-table-wrapper table .btn-group .btn{
    position: static;
    float: none;
    will-change: inherit;
}

.fixed-header-table-wrapper table .glyphicon{
    position: static;
}


/* ==========================================================================
 Widgets styles
========================================================================== */
.box-turquoise {background-color: #01c0c8}
.box-orange {background-color: #fb9678}
.box-green {background-color: #00c292}

.box-orange, .box-turquoise, .box-green {
    color: white;
}

.infobox p {opacity: 0.5;}

/* ==========================================================================
 Meal-type-input styles
========================================================================== */
.meal-type-input {
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
}
.meal-type-input input {
    width: auto;
    flex-grow: 2;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.meal-type-input button {
    border-left: 1px solid lightgray;
    padding: 7px 12px;
}

/* ==========================================================================
 Attendance page styles
========================================================================== */
.atd-page .date-navigation {
    padding-top: 4px;
}

.sch-table.table th.fixcol {
    background-color: #4F5467;
}

.sch-table .th-prime {
    background-color: #4F5467;
    font-size: 14px;
}
.sch-table .th-prime th {
    vertical-align: middle;
    font-weight: normal;
    color: white;
}

.sch-table .th-second { background-color: #f2f2f2; }
.sch-table .th-second th { border-bottom: 1px solid #bababa; }

/** col day */
.sch-table .col-day {
    text-align: center;
    vertical-align: middle;
    border-left: 1px solid #efefef;
}
.sch-table .col-day.day-off { color: #ccc; }
.sch-table td.col-day.day-off { background-color: #fafafa; }

.sch-table th.col-day {border-left: 1px solid #d2d2d2;}

.sch-table tr.details,
.sch-table tr.details td {
    border-color: #fff;
}

.sch-table tr > td.col-day.today.chekd,
.page-legend .legend-item.chekd {
    background-color: #B2DFDB;
}

.sch-table tr:hover > td.col-day.dirty,
.sch-table tr > td.col-day.today.dirty.chekd,
.sch-table tr > td.col-day.dirty,
.page-legend .legend-item.dirty { background-color: #ffe082}

.sch-table tr:hover > td.col-day.dirty,
.sch-table tr > td.col-day.today.dirty.chekd,
.sch-table tr > td.col-day.dirty { background-color: #ffe082}

.sch-table tr.info:hover > td.col-day.processed,
.sch-table tr.info > td.col-day.processed,
.page-legend .legend-item.processed { background-color: #addaf1}

.sch-table tr:hover > td.col-day.danger,
.sch-table tr > td.col-day.danger,
.page-legend .legend-item.danger { background-color: #FFCDD2; border-top-color: white;}

.sch-table tr.success:hover > td.col-day.processed,
.sch-table tr.success > td.col-day.processed { background-color: #bfe2b1}

.sch-table .th-prime th.col-day.today { background-color: #4CAF50; color: white; }

.sch-table .th-second th.col-day.today,
.sch-table td.col-day.today {
    border-left: 1px solid #4CAF50;
    border-right: 1px solid #4CAF50;
}

.sch-table .col-day.mark {
    position: relative;
    background-color: inherit;
}

.sch-table .col-day.mark::before,
.sch-table .col-day.mark::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
}

.sch-table .col-day.mark::after {
    border-width: 5px;
    border-right-color: #0c0;
    border-top-color: #0c0;
}

/** END: col day */

.sch-table .col-balance,
.sch-table tr.details .col-balance {
    border-left: 1px solid #c2c2c2;
    color: #009688;
}

.sch-table .col-fill-right {
    border-left: 1px solid #c2c2c2;
    width: 40px;
}

.blnc.debt,
.sch-table .col-balance.debt,
.sch-table .col-balance .debt {
    color: #ff5722;
}

.sch-table .col-balance .sub {
    position: relative;
    bottom: 7px;
    font-size: 0.7em;
}

.sch-table .pupil-link {
    cursor: pointer;
}
.sch-table .pupil-link .exp {
    float: right;
    transition: 200ms;
}
.sch-table .pupil-link .exp.expanded {
    transform: rotateZ(90deg);
}
.sch-table .pupil-link:hover {
    color: #222;
}
.sch-table .pupil-link:hover .exp {
    color: #f57c00;
}

.sch-table tr.dirty td:hover,
.sch-table tr.dirty td {
    background-color: #fff3cd;
}

@media (min-width: 256px) and (max-width: 992px) {
    .input-group-addon, .input-group-btn, .input-group .form-control {
        display: inline;
    }
    .form-control.grade-input {
        width: 70px;
    }
}
.form-control[readonly].grade-input,
.form-control.grade-input {
    background-color: white;
}


/* ==========================================================================
 Lessons page styles
========================================================================== */
.sch-table .btn-group .btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.date-value {border-bottom: 1px dotted;}
.date-navigation, .date-navigation a {
    color: #777;
    font-size: 12px;
    text-decoration: none;
}

.date-navigation .date-nav-prev {margin-right: 0.5em;}
.date-navigation .date-nav-next {margin-left: 0.5em;}
.date-navigation .date-nav-today {margin-left: 1em;}

.date-navigation .date-nav-today {
    border-radius: 1em;
    padding: 2px 8px;
    background-color: #4CAF50;
    color: white;
}

.date-navigation a.goto-today:hover {
    background-color: #388E3C;
}

@media (min-width: 768px) {
    #lesson-clazz-table td.fixcol {
        min-width: 200px;
    }
}

/* ==========================================================================
 Page legends
  */

.page-legend ul.legend-items {
    list-style: none;
    padding-left: 1em;
    margin: 1em 0;
}
.page-legend .legend-item {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
}


/* ==========================================================================
  Modals
========================================================================== */
@media screen and (min-width: 768px) {
    .cookie-consent {
        display: flex;
        align-items: center;
    }
    .cookie-consent .modal-body {
        flex: 2;
    }
    .cookie-consent .modal-actions {
        flex: 1;
    }    
}

.cookie-consent {
    z-index: 1070 !important;
    background-color: white;
    position: fixed;
    top: auto;
    bottom: 0;
    width: 100%;
    box-shadow: 0 24px 48px 0 rgba(0,0,0,.32),0 4px 12px 0 rgba(0,0,0,.12)
}
.cookie-consent .modal-body {
    text-align: left;
}
.cookie-consent .modal-actions {
    padding: 15px;
}
